import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ConsultorioService {
  async getConsultoriosAll() {
    const consultorios = await fetchWrapper.get(`${ruta}/consultorios`);
    return consultorios;
  }

  async sendConsultorioNew(datos) {
    const nuevomedico = await fetchWrapper.post(`${ruta}/consultorios`, datos);
    return nuevomedico;
  }

  async updateConsultorio(datos) {
    const medicoActualizado = await fetchWrapper.put(
      `${ruta}/consultorios/` + datos.id,
      datos
    );
    return medicoActualizado;
  }

  async deleteConsultorio(id) {
    return await fetchWrapper.delete(`${ruta}/consultorios/${id}`);
  }
}